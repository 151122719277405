import React, { useEffect, useState } from 'react';
import TextInput from '../../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import ValidationMessage from '../../ui/ValidationMessage';
import { API_CODE, FILE_UPLOAD_TYPE, MUI_COLORS, REGEX } from '../../../constants/enum';
import { defaultValidationMessage } from '../../../helper/helpers';
import { connect } from 'react-redux';
import * as userProfileActions from '../../profile/reduxStore/action';
import Success from '../../../images/icons/success-icon.svg';
import PropTypes from 'prop-types';
import { getUser } from '../../authService/auth';
import Snackbar from '../../../components/Snackbar';
import Image from '../../../components/Image';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import UploadImage from '../../common/UploadImage';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action';

function ProfileSetting({ getUserProfile }) {
  const user = getUser();
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [profileData, setProfileData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState('');
  const emailRegex = REGEX.EMAIL_REGEX;

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitProfileDetails = () => {};

  const getUserProfileInfo = async () => {
    const getUserInfo = await getUserProfile();
    if (getUserInfo?.status === API_CODE?.STATUS_200) {
      setProfileData(getUserInfo?.data?.data);
      setSelectedImage(
        getUserInfo?.data?.data?.profileImage
          ? [{ preview: getUserInfo?.data?.data?.profileImage, type: FILE_UPLOAD_TYPE.URL }]
          : ''
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserProfileInfo();
  }, []);

  return (
    <>
      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={message?.messageTitle}></XSText>
              <MSText textColor="text-gray-500" title={message?.contentMessage}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
      {isLoading ? (
        <div className="h-full flex items-center">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="box-shadow rounded-2xl bg-white pt-6 pb-8 p-6">
            <div className="flex md:flex-row flex-col w-full">
              <div className="w-full flex justify-center">
                <form
                  id="profile-form"
                  className="comapny-setting-form-container"
                  onSubmit={handleSubmit(onSubmitProfileDetails)}
                >
                  <div>
                    <TextInput
                      defaultValue={profileData?.firstName}
                      textFieldContainerClass="w-full mb-4"
                      name="firstName"
                      label="First name"
                      variant="filled"
                      disabled={true}
                      inputRef={register({
                        required: false
                      })}
                      error={errors.firstName ? true : false}
                      helperText={
                        errors?.firstName && <ValidationMessage title={defaultValidationMessage('First name')} />
                      }
                    />
                    <TextInput
                      defaultValue={profileData?.lastName}
                      textFieldContainerClass="w-full mb-4"
                      name="lastName"
                      label="Last name"
                      variant="filled"
                      disabled={true}
                      inputRef={register({
                        required: false
                      })}
                      error={errors.lastName ? true : false}
                      helperText={
                        errors?.firstName && <ValidationMessage title={defaultValidationMessage('Last name')} />
                      }
                    />
                    <TextInput
                      defaultValue={user?.email}
                      name="email"
                      textFieldContainerClass="w-full mb-4"
                      label="Email Address"
                      variant="filled"
                      disabled={true}
                      inputRef={register({
                        required: true,
                        pattern: {
                          value: emailRegex,
                          message: 'Please fill in a valid Email Address'
                        }
                      })}
                      error={errors.email ? true : false}
                      helperText={
                        errors.email && <ValidationMessage title={defaultValidationMessage('Email address')} />
                      }
                    />
                    <TextInput
                      defaultValue={profileData?.phoneNumber}
                      name="phone"
                      label="Phone Number"
                      disabled={true}
                      textFieldContainerClass="w-full"
                      inputRef={register({
                        required: false
                      })}
                      variant="filled"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile())
  };
};

export default connect(null, mapDispatchToProps)(ProfileSetting);
ProfileSetting.propTypes = {
  getUserProfile: PropTypes.func
};
