import * as React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Typography, Stack } from '@mui/material';
import Avatar from '../../components/ui/Avatar';
import { MUI_COLORS, STATUS_COLORS } from '../../constants/enum';
import Iconify from '../../components/Iconify';
import MSText from '../../components/ui/typography/MSText';
import CompanyStatus from '../../components/ui/CompanyStatus';
import { convertDateToDDMMYYYY } from '../../helper/helpers';
import useIsMobile from '../../../utils/isMobile';

MailRoomTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  handleViewAction: PropTypes.func,
  handleShareAction: PropTypes.func
};

export default function MailRoomTableRow({ row, selected, handleViewAction, handleShareAction }) {
  const { documentName, Identity, uploadedDate } = row;

  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile && (
        <TableRow
          hover
          selected={selected}
          sx={{
            borderBottom: `1px solid ${STATUS_COLORS.GREY}`,
            '&:last-child td, &:last-child th': { borderBottom: 0 }
          }}
        >
          <TableCell sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleViewAction}>
            <Typography
              sx={{
                color: MUI_COLORS.MID_NIGHT,
                textDecoration: 'none',
                boxShadow: 'none',
                fontSize: '16px',
                fontWeight: 400
              }}
              variant="subtitle2"
              noWrap
            >
              {documentName}
            </Typography>
          </TableCell>

          <TableCell className="cursor-pointer" align="right" onClick={handleViewAction}>
            {Identity?.map((status) => (
              <span className="ml-2">
                <CompanyStatus requiredCompanyStatus={status} />
              </span>
            ))}
            <span className="ml-4">{convertDateToDDMMYYYY(uploadedDate)}</span>
          </TableCell>

          <TableCell align="right">
            <div className="flex justify-end">
              <div className="bg-customBg-mainBg text-coral-500 rounded-lg  p-2 cursor-pointer">
                <Iconify icon={'mdi:share'} onClick={handleShareAction} />
              </div>
              <div
                className="bg-customBg-mainBg text-coral-500 rounded-lg ml-5 p-2 cursor-pointer"
                onClick={handleViewAction}
              >
                <Iconify icon={'ph:eye'} />
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
      {isMobile && (
        <TableRow
          hover
          selected={selected}
          sx={{
            borderBottom: `1px solid ${STATUS_COLORS.GREY}`,
            '&:last-child td, &:last-child th': { borderBottom: 0 }
          }}
          className="flex flex-col"
        >
          <div className="flex justify-between ">
            <TableCell className="flex flex-col" sx={{ padding: '16px !important' }}>
              <Typography
                sx={{
                  color: MUI_COLORS.MID_NIGHT,
                  textDecoration: 'none',
                  boxShadow: 'none',
                  fontSize: '16px',
                  fontWeight: 400
                }}
                variant="subtitle2"
                noWrap
              >
                {documentName}
              </Typography>
              {Identity?.map((status) => (
                <span className="">
                  <CompanyStatus requiredCompanyStatus={status} />
                </span>
              ))}
            </TableCell>

            <TableCell className="cursor-pointer flex flex-col" align="right" sx={{ padding: '16px !important' }}>
              <span className="ml-4">{convertDateToDDMMYYYY(uploadedDate)}</span>
              <div className="flex justify-end">
                <div className="bg-customBg-mainBg text-coral-500 rounded-lg  p-2 cursor-pointer">
                  <Iconify icon={'mdi:share'} onClick={handleShareAction} />
                </div>
                <div
                  className="bg-customBg-mainBg text-coral-500 rounded-lg ml-5 p-2 cursor-pointer"
                  onClick={handleViewAction}
                >
                  <Iconify icon={'ph:eye'} />
                </div>
              </div>
            </TableCell>
          </div>
        </TableRow>
      )}
    </>
  );
}
