import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import useTable, { emptyRows } from '../../../hooks/useTable';
import { TableNoData, TableEmptyRows } from '../../table';
import XSText from '../../ui/typography/XSText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import BaseText from '../../ui/typography/BaseText';
import Scrollbar from '../../Scrollbar';
import CommonCompanyRow from '../../../sections/@company/CommonCompanyRow';
import ViewDetailsModal from '../common/ViewDetailsModal';
import { downloadImage } from '../../../helper/helpers';
import { STATUS_COLORS } from '../../../constants/enum';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action';

const latestUpdateData = [
  { email: 'j.campbell@acme.com', firstName: 'Johan Bach', service: ['Shareholder', 'Director'], date: '19 May 2021' },
  {
    email: 'j.campbell@acme.com',
    firstName: 'King Francis',
    service: ['Shareholder', 'Director'],
    date: '19 May 2021'
  },
  { email: 'j.campbell@acme.com', firstName: 'Jonathan Cusimano', service: ['Shareholder'], date: '19 May 2021' },
  { email: 'j.campbell@acme.com', firstName: 'Jonathan Cusimano', service: ['Director'], date: '19 May 2021' }
];

function DirectorsTable({ directorsDetails, isStackHolderLoading, previewDocument }) {
  const { page, rowsPerPage } = useTable({
    defaultOrderBy: 'dateCreated'
  });
  const [tableData, setTableData] = useState([]);

  const [open, setOpen] = useState(false);
  const [directorData, setDirectorData] = useState();
  const [previewDocumentUrl, setPreviewDocumentUrl] = useState();
  const [previewDocumentUrlLoading, setPreviewDocumentUrlLoading] = useState(true);
  const isNotFound = tableData?.length === 0;

  const closeModal = () => {
    setOpen(false);
  };

  const handleViewShareHolder = async (data) => {
    setPreviewDocumentUrlLoading(true);
    setPreviewDocumentUrl('');
    setDirectorData(data);
    setOpen(true);
    const previewDocumentResponse =
      (data?.passportDocument?.documentCode || data?.passportDocument?.documentUrl) &&
      (await previewDocument({
        [`${data?.passportDocument?.documentCode ? 'code' : 'documentUrl'}`]: data?.passportDocument?.documentCode
          ? data?.passportDocument?.documentCode
          : data?.passportDocument?.documentUrl,
        newVersion: data?.passportDocument?.documentCode ? true : false
      }));
    setPreviewDocumentUrl(previewDocumentResponse?.data);
    setPreviewDocumentUrlLoading(false);
  };

  const handleDownloadShareHolderPassport = async (data) => {
    const previewDocumentResponse =
      data?.passportDocument &&
      (await previewDocument({
        [`${data?.passportDocument?.documentCode ? 'code' : 'documentUrl'}`]: data?.passportDocument?.documentCode
          ? data?.passportDocument?.documentCode
          : data?.passportDocument?.documentUrl,
        newVersion: data?.passportDocument?.documentCode ? true : false
      }));
    downloadImage(previewDocumentResponse?.data, `${data?.firstName}-passport`);
  };
  useEffect(() => {
    setTableData(directorsDetails);
  }, [directorsDetails]);

  return (
    <>
      {tableData?.length > 0 && (
        <Card
          sx={{
            '&.MuiPaper-root': {
              position: 'unset',
              boxShadow: 'none',
              border: `1px solid ${STATUS_COLORS.GREY}`
            }
          }}
        >
          <div className="m-4 sm:m-6">
            <BaseText title="Director(s)" fontSize="text-lg" textColor="text-blue-100" fontWeight="text-bold" />
          </div>
          <Divider />
          <Scrollbar>
            <TableContainer sx={{ position: 'relative' }}>
              <Table>
                <TableBody>
                  {isStackHolderLoading ? (
                    <StatrysLoader />
                  ) : (
                    <>
                      {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <>
                          <CommonCompanyRow
                            row={row}
                            handleViewAction={() => handleViewShareHolder(row)}
                            handleDownloadAction={() => handleDownloadShareHolderPassport(row)}
                          />
                        </>
                      ))}
                    </>
                  )}

                  <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      )}
      <ViewDetailsModal
        previewDocumentUrl={previewDocumentUrl}
        previewDocumentUrlLoading={previewDocumentUrlLoading}
        open={open}
        shareholderData={directorData}
        closeModal={closeModal}
        title="View director"
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    previewDocument: (uploadedImage) => dispatch(commonReduxActions.previewDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(DirectorsTable);
DirectorsTable.propTypes = { directorsDetails: PropTypes.array, isStackHolderLoading: PropTypes.bool };
