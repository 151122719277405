import React from 'react';
import BaseText from '../../ui/typography/BaseText';
import MSText from '../../ui/typography/MSText';
import PhoneIcon from '../../../images/icons/company/phone-icon.svg';
import WhatsaapIcon from '../../../images/icons/company/whatsapp-icon.svg';
import MailBoxIn from '../../../images/icons/company/mailbox-in.svg';
import { CONTACT_INFO } from '../../../constants/enum';

function CompanySecretary() {
  return (
    <div className="bg-white mt-6 p-4 sm:p-6 rounded-2xl border w-full  sm:w-[344px]">
      <BaseText className="mb-6" fontSize="text-xl" fontWeight="text-bold" title="Company secretary" />
      <div className="mb-4">
        <MSText className="mb-2" title="Statrys Corporate Services Limited" />
      </div>
      <div className="flex mb-2">
        <img src={PhoneIcon} alt="phone icon" />
        <MSText
          className="ml-2"
          textColor="text-coral-500"
          fontWeight="text-bold"
          title={<a href={CONTACT_INFO.TELEPHONE_LINK}>+852 5803 2818</a>}
        />
      </div>
      <div className="flex mb-2">
        <img src={WhatsaapIcon} alt="whatsapp icon" />
        <MSText
          className="ml-2"
          textColor="text-coral-500"
          fontWeight="text-bold"
          title={<a href={CONTACT_INFO.WHATSAPP_LINK}>+852 6452 3564</a>}
        />
      </div>
      <div className="flex">
        <img src={MailBoxIn} alt="mail box in" />
        <MSText
          className="ml-2"
          textColor="text-coral-500"
          fontWeight="text-bold"
          title={<a href={CONTACT_INFO.SUPPORT_EMAIL}>incorporation@statrys.com</a>}
        />
      </div>
    </div>
  );
}

export default CompanySecretary;
