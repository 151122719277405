import { useState, useEffect } from 'react';
import { isBrowser } from '../src/helper/helpers';

const useIsTablet = () => {
  const MAX_TAB_WIDTH = 1024;
  const [isTablet, setIsTablet] = useState(isBrowser() && window.innerWidth < MAX_TAB_WIDTH);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < MAX_TAB_WIDTH);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isTablet;
};

export default useIsTablet;
