import { useState, useEffect } from 'react';
import { isBrowser } from '../src/helper/helpers';

const useIsMobile = () => {
  const MOBILE_WIDTH = 640;
  const [isMobile, setIsMobile] = useState(isBrowser() && window.innerWidth < MOBILE_WIDTH);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
