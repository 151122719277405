import React from 'react';
import CompanyInformation from './profile/CompanyInformation';
import CompanySecretary from './profile/CompanySecretary';
import ShareHoldersAndDirectorsTable from './profile/ShareholderAndDirectors';
import UpdatesCompanyTable from './profile/UpdatesCompanyTable';
import PropTypes from 'prop-types';
import useIsMobile from '../../../utils/isMobile';

function Profile({
  profileDetails,
  stackHolderDetails,
  latestUpdatesDetails,
  isLoading,
  isLatestUpdatesLoading,
  isStackHolderLoading,
  handleToShowLatestData
}) {
  const isMobile = useIsMobile();
  return (
    <div className="flex flex-col gap-6 sm:gap-0  sm:flex-row mt-8">
      <div className="md:mr-6 px-2">
        <CompanyInformation profileDetails={profileDetails} isLoading={isLoading} />
        <CompanySecretary />
      </div>
      <div className="w-full px-2 sm:px-0">
        <UpdatesCompanyTable
          latestUpdatesDetails={latestUpdatesDetails}
          isLatestUpdatesLoading={isLatestUpdatesLoading}
          handleToShowLatestData={handleToShowLatestData}
        />
        <div className="mt-6">
          <ShareHoldersAndDirectorsTable
            stackHolderDetails={stackHolderDetails}
            isLoading={isLoading}
            isStackHolderLoading={isStackHolderLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default Profile;

Profile.propTypes = {
  companyProfileDetails: PropTypes.func
};
