import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField
} from '@mui/material';
import useTable, { emptyRows, getComparator } from '../../../hooks/useTable';
import { TableNoData, TableEmptyRows, TableHeadCustom } from '../../table';
import XSText from '../../ui/typography/XSText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import BaseText from '../../ui/typography/BaseText';
import Scrollbar from '../../Scrollbar';
import { ROW_PER_PAGE_OPTIONS, STATUS_COLORS } from '../../../constants/enum';
import MailRoomTableRow from '../../../sections/@company/MailRoomTableRow';
import Iconify from '../../Iconify';
import MailRoomViewTable from './MailRoomView';
import { useLocation } from '@reach/router';
import ShareDocumentByLink from '../common/ShareDocumentByLink';
import useIsMobile from '../../../../utils/isMobile';

const TABLE_HEAD = [
  { id: 'title', label: 'Folder name', align: 'left' },
  { id: 'date', label: 'Last modified', align: 'right' },
  { id: '', label: '', width: '190px' }
];

function MailRoomTable({ documentsDetails, isDocumentsLoading, companyId }) {
  const {
    page,
    order,
    orderBy,
    onSort,
    setPage,
    rowsPerPage,
    selected,
    onSelectAllRows,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({
    defaultOrderBy: 'dateCreated'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [viewRowData, setViewRowData] = useState();
  const [id, setId] = useState();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleShareModal = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleViewDetails = (id, rowData) => {
    setId(id);
    setSelectedRow(id);
    setViewRowData(rowData);
    setIsViewDetails(true);
  };
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const isNotFound = dataFiltered?.length === 0;

  useEffect(() => {
    if (documentsDetails) {
      setTableData(documentsDetails);
    } else {
      setTableData([]);
    }
  }, [documentsDetails]);

  useEffect(() => {
    if (location?.state?.isView) {
      handleViewDetails(location?.state?.id, location?.state?.mailRoomData);
    }
  }, [location?.state?.isView]);

  const isMobile = useIsMobile();
  return (
    <>
      {!isViewDetails ? (
        <Card
          sx={{
            maxWidth: isMobile ? '98vw' : '100%',
            '&.MuiPaper-root': {
              position: 'unset',
              boxShadow: 'none',
              border: `1px solid ${STATUS_COLORS.GREY}`
            }
          }}
        >
          <div className="flex justify-between m-4 sm:m-6 items-center">
            <BaseText title="Mailroom" fontSize="text-lg" textColor="text-blue-100" fontWeight="text-bold" />
            <div>
              <TextField
                fullWidth
                value={filterName}
                onChange={(event) => handleFilterName(event.target.value)}
                className="max-w-[177px] sm:max-w-full"
                sx={{
                  '& .MuiInputBase-input': {
                    marginTop: '0px',
                    padding: '8px'
                  },
                  '& .MuiInputAdornment-outlined': {
                    marginTop: '0px'
                  },
                  '& .MuiInputAdornment-outlined': {
                    marginTop: '0px'
                  }
                }}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={'eva:search-fill'}
                        sx={{ color: 'text.disabled', width: 20, height: 20, marginTop: '-1px' }}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </div>
          {!isMobile && (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?._id)
                      )
                    }
                  />
                  <TableBody>
                    {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <>
                        <MailRoomTableRow
                          handleShareAction={() => handleShareModal(row?._id)}
                          handleViewAction={() => handleViewDetails(row?._id, row)}
                          row={row}
                        />
                      </>
                    ))}

                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}
          {isMobile && (
            <TableContainer sx={{ position: 'relative' }}>
              <Table>
                <div className="flex justify-between bg-[#F3F6F8] p-4">
                  {TABLE_HEAD?.map((head) =>
                    head.label ? (
                      <span className="font-bold" key={head.id}>
                        {head.label}
                      </span>
                    ) : null
                  )}
                </div>
                <TableBody>
                  {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <>
                      <MailRoomTableRow
                        handleShareAction={() => handleShareModal(row?._id)}
                        handleViewAction={() => handleViewDetails(row?._id, row)}
                        row={row}
                      />
                    </>
                  ))}

                  <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[
                ROW_PER_PAGE_OPTIONS.TEN,
                ROW_PER_PAGE_OPTIONS.TWENTY_FIVE,
                ROW_PER_PAGE_OPTIONS.FIFTY
              ]}
              component="div"
              count={dataFiltered?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
          <ShareDocumentByLink
            openModal={open}
            closeDynamicModal={() => setOpen(false)}
            id={companyId}
            documentURLQuery={`mailroomDocId=${id}`}
          />
        </Card>
      ) : (
        <>
          {isViewDetails && selectedRow !== '' ? (
            <MailRoomViewTable
              selectedRow={selectedRow}
              documentsDetails={documentsDetails}
              viewRowData={viewRowData}
              companyId={companyId}
            />
          ) : (
            <StatrysLoader />
          )}
        </>
      )}
    </>
  );
}

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item) => item?.documentName?.toLowerCase()?.indexOf(filterName.toLowerCase()) !== -1
    );
  }
  return tableData;
}

export default MailRoomTable;

MailRoomTable.propTypes = {
  documentsDetails: PropTypes.array,
  isDocumentsLoading: PropTypes.bool
};
