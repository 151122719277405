import React from 'react';
import { navigate } from 'gatsby';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import Avatar from '../ui/Avatar';
import Badge from './Badge';
import AddSquare from '../../images/icons/company-incorporation/add-Circle.svg';
import { PATH_PAGE } from '../../routes/paths';
import { connect } from 'react-redux';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { API_CODE, SESSION_STORAGE_NAME } from '../../constants/enum';
import XSText from '../ui/typography/XSText';
import { RENEWAL_STATUS } from '../../constants/enum';

const CompanyList = ({ data, toSwitchCompany }) => {
  const isAnyCompanyExpiring = data?.companies?.find(
    (company) => company?.renewalStatus === RENEWAL_STATUS.MANUAL && company?.isApproved === true
  );
  const companiesExpiring = data?.companies?.filter(
    (company) => company.renewalStatus === RENEWAL_STATUS.MANUAL && company?.isApproved === true
  );
  const isAnyApprovedCompany = data?.companies?.find((company) => company?.isApproved === true);
  const isAnyOngoingCompany = data?.companies?.find((company) => company?.finishedApplication === false);
  const isAnyUnderReviewCompany = data?.companies?.find(
    (company) => company?.finishedApplication === true && company?.isApproved === false
  );

  const redirectToRespectiveFlow = async (companyDetails) => {
    if (companyDetails?.isApproved) {
      const payload = {
        email: companyDetails?.email,
        companyId: companyDetails?._id
      };
      sessionStorage.setItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED, true);
      const response = await toSwitchCompany(payload);
      if (response?.status === API_CODE.STATUS_200) {
        sessionStorage.setItem('token', response.data?.data?.token);
        sessionStorage.setItem('companyId', response.data?.data?.companyDetails?._id);
        navigate(PATH_PAGE.companyProfile);
      }
    } else if (companyDetails?.finishedApplication === true && companyDetails?.isApproved === false) {
      sessionStorage.setItem('companyId', companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
    } else if (companyDetails?.finishedApplication === false) {
      sessionStorage.setItem('companyId', companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
    }
  };

  const createNewCompany = () => {
    navigate(`${PATH_PAGE.signUp}/start`);
  };

  const handleCompanyExpiring = (companyDetails) => {
    navigate(PATH_PAGE.renewPayment, {
      state: {
        companyDetails: companyDetails,
        userDetails: data
      }
    });
  };
  const fullName = data?.firstName + ' ' + data?.lastName;
  return (
    <div className="bg-white md:w-[448px] w-auto h-auto rounded-2xl">
      <div className="md:p-8 p-6">
        <MDSubtitleText title={`Welcome, ${fullName}!`} fontWeight="text-regular" />
        <div className="pt-8">
          {(isAnyApprovedCompany || isAnyUnderReviewCompany || isAnyCompanyExpiring) && (
            <XSText title="Select a company" />
          )}
          <div className="flex flex-col gap-2 mt-4">
            <>
              {/* Completed and approved applications which has renewal pending false */}
              {data?.companies
                ?.filter(
                  (companyDetails) =>
                    companyDetails?.finishedApplication === true &&
                    companyDetails?.isApproved === true &&
                    companyDetails?.renewalStatus != RENEWAL_STATUS.MANUAL
                )
                ?.map((companyDetails, index) => {
                  return (
                    <div
                      className="border border-gray-400 rounded-lg cursor-pointer"
                      onClick={() => redirectToRespectiveFlow(companyDetails)}
                      key={index}
                    >
                      <div className="flex flex-row items-center p-4 gap-4">
                        <Avatar
                          avatarName={companyDetails?.companyName.charAt(0)}
                          fontSize="text-base"
                          borderRadius="rounded-[10px]"
                        />
                        <div className="flex flex-col gap-1">
                          <XSText title={companyDetails?.companyName} fontWeight="text-bold" />
                          {!companyDetails?.isApproved && <Badge color="green-200" title="Under Review" />}
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* Completed and not approved applications */}
              {data?.companies
                ?.filter(
                  (companyDetails) =>
                    companyDetails?.finishedApplication === true && companyDetails?.isApproved === false
                )
                ?.map((companyDetails, index) => {
                  return (
                    <div
                      className="border border-gray-400 rounded-lg cursor-pointer"
                      onClick={() => redirectToRespectiveFlow(companyDetails)}
                      key={index}
                    >
                      <div className="flex flex-row items-center p-4 gap-4">
                        <Avatar
                          avatarName={companyDetails?.companyName.charAt(0)}
                          fontSize="text-base"
                          borderRadius="rounded-[10px]"
                        />
                        <div className="flex flex-col gap-1">
                          <XSText title={companyDetails?.companyName} fontWeight="text-bold" />
                          {!companyDetails?.isApproved && <Badge color="green-200" title="Under Review" />}
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* Completed and approved applications which has renewal pending true */}
              {companiesExpiring?.map((companyDetails, index) => {
                return (
                  <>
                    <div
                      className="border border-gray-400 rounded-lg cursor-pointer"
                      onClick={() => {
                        if (!JSON?.parse(sessionStorage?.getItem('closeRenewalModalClicked'))) {
                          handleCompanyExpiring(companyDetails);
                        } else {
                          redirectToRespectiveFlow(companyDetails);
                        }
                      }}
                      key={index}
                    >
                      <div className="flex flex-row items-center p-4 gap-4">
                        <Avatar
                          avatarName={companyDetails?.companyName.charAt(0)}
                          fontSize="text-base"
                          borderRadius="rounded-[10px]"
                        />
                        <div className="flex flex-col gap-1">
                          <XSText title={companyDetails?.companyName} fontWeight="text-bold" />
                          <Badge color="yellow-500" title="License Expiring Soon" />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          </div>
        </div>
        {isAnyOngoingCompany && <XSText title="Continue your application" className="mt-8" />}

        {/* Not completed ongoing applications */}
        <div className={`flex flex-col gap-2 ${isAnyOngoingCompany && 'mt-4'}`}>
          {data?.companies?.map((companyDetails, index) => {
            return (
              <>
                {companyDetails?.finishedApplication === false && (
                  <div
                    className="border border-gray-400 rounded-lg cursor-pointer"
                    onClick={() => redirectToRespectiveFlow(companyDetails)}
                    key={index}
                  >
                    <div className="flex flex-row items-center p-4 gap-4">
                      <Avatar
                        avatarName={companyDetails?.companyName.charAt(0)}
                        fontSize="text-base"
                        borderRadius="rounded-[10px]"
                      />
                      <div className="flex flex-col gap-1">
                        <XSText title={companyDetails?.companyName} fontWeight="text-bold" />
                        <Badge color="salmon-500" title="Ongoing Application" />
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>

        <div className="border border-gray-400 rounded-lg cursor-pointer mt-10" onClick={createNewCompany}>
          <div className="flex flex-row items-center p-4 gap-4">
            <div className="bg-green-200 rounded-lg w-[38px] h-[38px] flex items-center justify-center">
              <img src={AddSquare} />
            </div>
            <XSText title="Register a new company" />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    toSwitchCompany: (companyPayload) => dispatch(CompanyAction.switchCompany(companyPayload))
  };
};
export default connect(null, mapDispatchToProps)(CompanyList);
