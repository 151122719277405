import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack } from '@mui/material';
import Avatar from '../../components/ui/Avatar';
import { MUI_COLORS } from '../../constants/enum';
import Iconify from '../../components/Iconify';
import MSText from '../../components/ui/typography/MSText';
import CompanyStatus from '../../components/ui/CompanyStatus';
import { makeFirstLetterCapitalize } from '../../helper/helpers';
import XMSText from '../../components/ui/typography/XMSText';
import CircularLoader from '../../components/ui/loaders/CircularLoader';
import useIsMobile from '../../../utils/isMobile';

CommonCompanyTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  handleViewAction: PropTypes.func,
  onDeleteRow: PropTypes.func,
  handleDownloadAction: PropTypes.func
};

export default function CommonCompanyTableRow({
  row,
  selected,
  handleViewAction,
  handleDownloadAction,
  previewDocumentUrlLoading
}) {
  const theme = useTheme();

  const { firstName, lastName, Identity, email, isDisabled, chineseName } = row;

  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile && (
        <TableRow hover selected={selected}>
          <TableCell
            sx={{ display: 'flex', alignItems: 'center' }}
            className="cursor-pointer"
            onClick={handleViewAction}
          >
            <div className="mr-2">
              <Avatar avatarImage="" avatarName={firstName?.charAt(0)} />
            </div>
            <Stack>
              <Typography
                sx={{ color: MUI_COLORS.MID_NIGHT, textDecoration: 'none', boxShadow: 'none' }}
                variant="subtitle2"
                noWrap
              >
                {chineseName && chineseName}{' '}
                {makeFirstLetterCapitalize(firstName) + ' ' + makeFirstLetterCapitalize(lastName)}
              </Typography>
              <MSText textColor="text-gray-450" title={email} />
            </Stack>
          </TableCell>

          {!isDisabled ? (
            <TableCell className="cursor-pointer" align="right" onClick={handleViewAction}>
              {Identity?.map((status) => (
                <span className="ml-2">
                  <CompanyStatus requiredCompanyStatus={status} />
                </span>
              ))}
            </TableCell>
          ) : (
            <TableCell className="cursor-pointer" align="right" onClick={handleViewAction}>
              {Identity?.map((status) => (
                <div className="px-2 rounded-md inline-block bg-gray-400 ml-2">
                  <XMSText
                    fontWeight="text-bold"
                    className="company-status-text"
                    textColor="text-gray-450"
                    title={status}
                  />
                </div>
              ))}
            </TableCell>
          )}

          <TableCell align="right">
            <div className="flex justify-end">
              <div
                className="bg-customBg-mainBg text-coral-500 rounded-lg p-2 cursor-pointer"
                onClick={handleViewAction}
              >
                <Iconify icon={'ph:eye'} />
              </div>
              {row?.passportDocument?.documentCode || row?.passportDocument?.documentUrl ? (
                <div
                  className="bg-customBg-mainBg text-coral-500 rounded-lg ml-5 p-2 cursor-pointer"
                  onClick={handleDownloadAction}
                >
                  {previewDocumentUrlLoading ? <CircularLoader /> : <Iconify icon={'lucide:download-cloud'} />}
                </div>
              ) : (
                <div className="w-[31px] h-8 ml-5"></div>
              )}
            </div>
          </TableCell>
        </TableRow>
      )}

      {isMobile && (
        <TableRow hover selected={selected} className="flex flex-col pb-6 border-b">
          <TableCell
            sx={{ display: 'flex', alignItems: 'center', padding: '16px !important', paddingBottom: '0px !important' }}
            className="cursor-pointer pb-0 "
            onClick={handleViewAction}
          >
            <div className="mr-2">
              <Avatar avatarImage="" avatarName={firstName?.charAt(0)} />
            </div>
            <Stack>
              <Typography
                sx={{ color: MUI_COLORS.MID_NIGHT, textDecoration: 'none', boxShadow: 'none' }}
                variant="subtitle2"
                noWrap
              >
                {chineseName && chineseName}{' '}
                {makeFirstLetterCapitalize(firstName) + ' ' + makeFirstLetterCapitalize(lastName)}
              </Typography>
              <span className={'text-xs text-gray-450'}> {email} </span>
            </Stack>
          </TableCell>

          <div className="flex gap-4 items-center justify-between pl-[60px]">
            {!isDisabled ? (
              <TableCell
                className="cursor-pointer p-0 pl-0"
                sx={{ paddingLeft: '0px !important' }}
                align="right"
                onClick={handleViewAction}
              >
                {Identity?.map((status) => (
                  <span className="ml-2">
                    <CompanyStatus requiredCompanyStatus={status} />
                  </span>
                ))}
              </TableCell>
            ) : (
              <TableCell className="cursor-pointer p-0" align="right" onClick={handleViewAction}>
                {Identity?.map((status) => (
                  <div className="px-2 rounded-md inline-block bg-gray-400 ml-2">
                    <XMSText
                      fontWeight="text-bold"
                      className="company-status-text"
                      textColor="text-gray-450"
                      title={status}
                    />
                  </div>
                ))}
              </TableCell>
            )}

            <TableCell align="right" className="p-0" sx={{ paddingRight: '16px !important' }}>
              <div className="flex justify-end">
                <div
                  className="bg-customBg-mainBg text-coral-500 rounded-lg p-2 cursor-pointer"
                  onClick={handleViewAction}
                >
                  <Iconify icon={'ph:eye'} />
                </div>
                {row?.passportDocument?.documentCode || row?.passportDocument?.documentUrl ? (
                  <div
                    className="bg-customBg-mainBg text-coral-500 rounded-lg ml-5 p-2 cursor-pointer"
                    onClick={handleDownloadAction}
                  >
                    {previewDocumentUrlLoading ? <CircularLoader /> : <Iconify icon={'lucide:download-cloud'} />}
                  </div>
                ) : (
                  <div className="w-[31px] h-8 ml-5"></div>
                )}
              </div>
            </TableCell>
          </div>
        </TableRow>
      )}
    </>
  );
}
