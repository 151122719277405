import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Divider } from '@mui/material';
import { MUI_COLORS, STRING_MAX_CHAR } from '../../constants/enum';
import Iconify from '../../components/Iconify';
import MSText from '../../components/ui/typography/MSText';
import CompanyStatus from '../../components/ui/CompanyStatus';
import XMSText from '../../components/ui/typography/XMSText';
import FileIcon from '../../images/icons/company/file-icon.svg';
import WhiteFileIcon from '../../images/icons/company/white-file-icon.svg';
import { Link } from 'gatsby';
import { convertDateToDDMMYYYY, downloadImage, stringTruncate } from '../../helper/helpers';
import * as commonReduxActions from '../../components/commonReduxStore/reduxStore/action';
import { connect } from 'react-redux';

CompanySecretaryRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  handleViewAction: PropTypes.func,
  handleShareAction: PropTypes.func,
  isView: PropTypes.bool,
  selectedRow: PropTypes.string,
  handleViewTableRow: PropTypes.func
};

function CompanySecretaryRow({
  row,
  selected,
  handleViewAction,
  handleShareAction,
  handleViewTableRow,
  isView,
  selectedRow,
  previewDocument
}) {
  const { documentName, documentUrl, uploadedDate, _id } = row;
  const handleDownloadDocument = async () => {
    if (row?.documentCode || row?.documentUrl) {
      const previewDocumentResponse = await previewDocument({
        [`${row?.documentCode ? 'code' : 'documentUrl'}`]: row?.documentCode ? row?.documentCode : row?.documentUrl,
        newVersion: row?.documentCode ? true : false
      });
      downloadImage(previewDocumentResponse?.data, row?.documentName);
    }
  };
  return (
    <>
      <Divider />
      <div
        className={`${
          isView && _id === selectedRow && 'bg-purple-500'
        } flex justify-between items-center py-3 pl-6  pr-6 cursor-pointer ${
          _id !== selectedRow && 'hover:bg-gray-150'
        }`}
        onClick={!isView ? handleViewAction : handleViewTableRow}
      >
        <div className="flex">
          <img src={isView && _id === selectedRow ? WhiteFileIcon : FileIcon} alt="file icon" />
          <div className="ml-4">
            {!isView ? (
              <MSText
                textColor={`${isView && _id === selectedRow && 'text-white'}`}
                fontWeight="text-bold"
                title={documentName}
              />
            ) : (
              <MSText
                textColor={`${isView && _id === selectedRow && 'text-white'}`}
                fontWeight="text-bold"
                title={stringTruncate(documentName, STRING_MAX_CHAR.TWENTY_FIVE)}
              />
            )}
            {isView && (
              <XMSText
                textColor={`${isView && _id === selectedRow && 'text-white'}`}
                title={convertDateToDDMMYYYY(uploadedDate)}
              />
            )}
          </div>
        </div>
        {!isView ? (
          <div className="flex items-center">
            <XMSText title={convertDateToDDMMYYYY(uploadedDate)} className="mr-2 sm:mr-20" />
            <div className="flex justify-end">
              <div className="bg-customBg-mainBg text-coral-500 rounded-lg  p-2 cursor-pointer">
                <Iconify icon={'mdi:share'} onClick={handleShareAction} />
              </div>
              <div
                className="bg-customBg-mainBg text-coral-500 rounded-lg ml-4 p-2 cursor-pointer"
                onClick={handleViewAction}
              >
                <Iconify icon={'ph:eye'} />
              </div>
            </div>
          </div>
        ) : (
          <>
            {isView && _id !== selectedRow && (
              <div className="flex items-center">
                <div
                  className="bg-customBg-mainBg text-coral-500 rounded-lg ml-4 p-2 cursor-pointer"
                  onClick={handleDownloadDocument}
                >
                  <Iconify icon={'lucide:download-cloud'} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    previewDocument: (uploadedImage) => dispatch(commonReduxActions.previewDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(CompanySecretaryRow);
