import React, { useState } from 'react';
import H4HeaderText from '../../ui/typography/H4HeaderText';
import DynamicModal from '../../ui/modal/DynamicModal';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Iconify from '../../Iconify';
import {
  checkValueExistOrNot,
  downloadImage,
  getFileExtension,
  makeFirstLetterCapitalize
} from '../../../helper/helpers';
import EmptyStageImg from '../../../images/icons/company/empty-stage-gray-cloud.svg';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { FILE_EXTENTION_TYPE } from '../../../constants/enum';
import useIsMobile from '../../../../utils/isMobile';

function ViewDetailsModal({ open, closeModal, shareholderData, previewDocumentUrlLoading, previewDocumentUrl }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function handlePageShifting(index) {
    setPageNumber(index + 1);
  }

  const isMobile = useIsMobile();
  return (
    <div>
      <DynamicModal openDynamicModal={open} closeDynamicModal={closeModal} maxWidth="920px">
        <H4HeaderText title="View corporate shareholder" />
        <div className="flex flex-col gap-8 sm:gap-0 max-h-[70vh] sm:flex-row my-6">
          <div>
            {previewDocumentUrlLoading ? (
              <StatrysLoader />
            ) : (
              <>
                {getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.PNG ||
                getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.JPEG ||
                getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.JPG ? (
                  <img
                    src={previewDocumentUrl ? previewDocumentUrl : EmptyStageImg}
                    alt="passport image"
                    className="h-60 object-contain"
                    width="364"
                    height="240"
                  />
                ) : (
                  <div>
                    <Document file={previewDocumentUrl} onLoadSuccess={onDocumentLoadSuccess}>
                      <div className="w-72">
                        <Page pageNumber={pageNumber} key={pageNumber} />
                      </div>
                      {pageNumber > 0 && (
                        <div className="importing-invoice-multi-pages flex mt-6 ">
                          {Array.from(new Array(numPages), (el, index) => (
                            <>
                              {numPages > 1 && (
                                <div className={`${index + 1 === pageNumber && 'active-pdf-tab'} mr-4`}>
                                  <Page
                                    className={`other-pdf-container cursor-pointer`}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={70}
                                    height={100}
                                    onClick={() => handlePageShifting(index)}
                                  />
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                    </Document>
                  </div>
                )}
              </>
            )}
            {previewDocumentUrl && !isMobile && (
              <div className="flex justify-end mt-2">
                <PrimaryButton
                  id="view-corporate-pdf-download"
                  caption={
                    <div className={`flex justify-center gap-2 items-center`}>
                      <Iconify icon={'lucide:download-cloud'} />
                      <span>Download</span>
                    </div>
                  }
                  onClick={() => downloadImage(previewDocumentUrl, shareholderData?.firstName)}
                  type="small"
                  bgColor="bg-white"
                  className="company-white-button mb-2"
                />
              </div>
            )}
          </div>

          <div className="sm:ml-8 w-64">
            <MSText textColor="text-gray-450 mb-2" title="Company name" />
            <XSText
              className="mb-4"
              title={checkValueExistOrNot(makeFirstLetterCapitalize(shareholderData?.companyName))}
            />
            <MSText textColor="text-gray-450 mb-2" title="Company chinese name" />
            <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.companyChineseName)} />
            <MSText textColor="text-gray-450 mb-2" title="Percentage of shares" />
            <XSText className="mb-4" title={`${checkValueExistOrNot(shareholderData?.percentageOfShares)}%`} />
            <MSText textColor="text-gray-450 mb-2" title="Country of registration" />
            <XSText className="mb-4 " title={checkValueExistOrNot(shareholderData?.countryOfRegistration?.name)} />
            <MSText textColor="text-gray-450 mb-2" title="Country of BR certificate number" />
            <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.brCertificateNumber)} />
          </div>

          {previewDocumentUrl && isMobile && (
            <div className="flex justify-start mb-6">
              <PrimaryButton
                id="view-corporate-pdf-download"
                caption={
                  <div className={`flex justify-center gap-2 items-center`}>
                    <Iconify icon={'lucide:download-cloud'} />
                    <span>Download</span>
                  </div>
                }
                onClick={() => downloadImage(previewDocumentUrl, shareholderData?.firstName)}
                type="small"
                bgColor="bg-white"
                className="company-white-button mb-2"
              />
            </div>
          )}
        </div>
      </DynamicModal>
    </div>
  );
}

export default ViewDetailsModal;
