import React, { useEffect, useState } from 'react';
import { Card, Divider, Table, TableBody, TableContainer } from '@mui/material';
import useTable, { emptyRows } from '../../../hooks/useTable';
import { TableNoData, TableEmptyRows } from '../../table';
import PropTypes from 'prop-types';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import BaseText from '../../ui/typography/BaseText';
import Scrollbar from '../../Scrollbar';
import CommonCompanyRow from '../../../sections/@company/CommonCompanyRow';
import ViewDetailsModal from '../common/ViewDetailsModal';
import { downloadImage } from '../../../helper/helpers';
import { STATUS_COLORS } from '../../../constants/enum';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action';
import { connect } from 'react-redux';

function ShareHoldersAndDirectorsTable({ stackHolderDetails, isStackHolderLoading, previewDocument }) {
  const { page, rowsPerPage } = useTable({
    defaultOrderBy: 'dateCreated'
  });
  const [open, setOpen] = useState(false);
  const [shareholderData, setShareholderData] = useState();
  const [previewDocumentUrl, setPreviewDocumentUrl] = useState();
  const [previewDocumentUrlLoading, setPreviewDocumentUrlLoading] = useState(true);

  const closeModal = () => {
    setOpen(false);
  };

  const handleViewShareHolder = async (data) => {
    setPreviewDocumentUrlLoading(true);
    setPreviewDocumentUrl('');
    setShareholderData(data);
    setOpen(true);
    const previewDocumentResponse =
      (data?.passportDocument?.documentCode || data?.passportDocument?.documentUrl) &&
      (await previewDocument({
        [`${data?.passportDocument?.documentCode ? 'code' : 'documentUrl'}`]: data?.passportDocument?.documentCode
          ? data?.passportDocument?.documentCode
          : data?.passportDocument?.documentUrl,
        newVersion: data?.passportDocument?.documentCode ? true : false
      }));
    setPreviewDocumentUrl(previewDocumentResponse?.data);
    setPreviewDocumentUrlLoading(false);
  };
  const handleDownloadShareHolderPassport = async (data) => {
    const previewDocumentResponse =
      data?.passportDocument &&
      (await previewDocument({
        [`${data?.passportDocument?.documentCode ? 'code' : 'documentUrl'}`]: data?.passportDocument?.documentCode
          ? data?.passportDocument?.documentCode
          : data?.passportDocument?.documentUrl,
        newVersion: data?.passportDocument?.documentCode ? true : false
      }));

    downloadImage(previewDocumentResponse?.data, `${data?.firstName}-passport`);
  };
  const [tableData, setTableData] = useState([]);

  const stackHolderAndDirector = (stackHolderDetails) => {
    stackHolderDetails?.map((stackHolder) => {
      if (!stackHolder?.isDisabled) {
        setTableData((prevState) => [...prevState, stackHolder]);
      }
    });
  };

  const isNotFound = tableData?.length === 0;

  useEffect(() => {
    if (stackHolderDetails?.length > 0) {
      stackHolderAndDirector(stackHolderDetails);
    } else {
      setTableData([]);
    }
  }, [stackHolderDetails]);

  return (
    <>
      <Card
        sx={{
          '&.MuiPaper-root': {
            position: 'unset',
            boxShadow: 'none',
            border: `1px solid ${STATUS_COLORS.GREY}`
          }
        }}
      >
        <div className="m-4 sm:m-6">
          <BaseText
            title="Shareholders and directors"
            fontSize="text-lg"
            textColor="text-blue-100"
            fontWeight="text-bold"
          />
        </div>
        <Divider />
        <Scrollbar>
          <TableContainer sx={{ position: 'relative' }}>
            <Table>
              <TableBody className="max-w-full">
                {isStackHolderLoading ? (
                  <StatrysLoader />
                ) : (
                  <>
                    {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <>
                        <CommonCompanyRow
                          handleViewAction={() => handleViewShareHolder(row)}
                          row={row}
                          handleDownloadAction={() => handleDownloadShareHolderPassport(row)}
                        />
                      </>
                    ))}

                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <ViewDetailsModal
        open={open}
        shareholderData={shareholderData}
        previewDocumentUrl={previewDocumentUrl}
        previewDocumentUrlLoading={previewDocumentUrlLoading}
        closeModal={closeModal}
        title="View shareholders and directors"
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    previewDocument: (uploadedImage) => dispatch(commonReduxActions.previewDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(ShareHoldersAndDirectorsTable);

ShareHoldersAndDirectorsTable.propTypes = {
  stackHolderDetails: PropTypes.array,
  isStackHolderLoading: PropTypes.bool,
  previewDocument: PropTypes.func
};
