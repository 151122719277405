import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import MSText from '../ui/typography/MSText';
import Avatar from '../ui/Avatar';
import { stringTruncate } from '../../helper/helpers';
import { SESSION_STORAGE_NAME, STRING_MAX_CHAR, SVG_TYPES } from '../../constants/enum';
import { Divider } from '@mui/material';
import '../../styles/headerNavigation.scss';
import Badge from '../companyList/Badge';
import Tick from '../../images/icons/check-box-tick.svg';
import { connect } from 'react-redux';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import { getSvgIcon } from '../../helper/svgHelper';
import { RENEWAL_STATUS } from '../../constants/enum';
import { EXTERNAL_LINKS } from '../../constants/enum';
import SkeletonRectangle from '../common/ReactangleSkeleton';

function NavigationDropDownLeft({ companyList, toSwitchCompany }) {
  const [onHover, setOnHover] = useState();
  const [openBussinessAccountList, setOpenBussinessAccountList] = useState(false);
  const [openInvoicingList, setOpenInvoicingList] = useState(false);

  const switchCompany = async (companyDetails) => {
    if (companyDetails?.isApproved) {
      const payload = {
        email: companyDetails?.email,
        companyId: companyDetails?._id
      };
      sessionStorage.setItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED, true);
      const response = await toSwitchCompany(payload);
      if (response?.status === API_CODE.STATUS_200) {
        sessionStorage.setItem('token', response.data?.data?.token);
        sessionStorage.setItem('companyId', response.data?.data?.companyDetails?._id);
        navigate('/');
      }
    } else if (companyDetails?.finishedApplication === true && companyDetails?.isApproved === false) {
      sessionStorage.setItem('companyId', companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
    } else if (companyDetails?.finishedApplication === false) {
      sessionStorage.setItem('companyId', companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
    }
  };

  const handleNewCompanyCreation = () => {
    navigate(`${PATH_PAGE.signUp}/start`);
    sessionStorage.setItem('companyId', '');
  };

  const handleSwitchToStatrys = () => {
    navigate(`${EXTERNAL_LINKS?.SWITCH_TO_STATRYS}`);
  };

  const isAnyOngoingCompany = companyList?.find((company) => company?.finishedApplication === false);

  const handleCompanyExpiring = (companyDetails) => {
    navigate(PATH_PAGE?.renewPayment, {
      state: {
        companyDetails: companyDetails
      }
    });
  };
  return (
    <>
      <div className={`absolute dropdown-content-container-left left-2 sm:left-[25px]`}>
        <div className="dropdown-content">
          <div className="tooltip-container">
            <div className="tooltip-icon " style={{ position: 'absolute', left: '0' }}></div>
          </div>
          <div
            className="dropdown-content-container pb-6 max-h-[600px] w-[339px] max-w-[95vw] overflow-scroll flex flex-col"
            onMouseLeave={() => setOnHover()}
          >
            {/* Company Incorporation List */}
            {companyList && (
              <>
                <div className="p-2">
                  {companyList
                    ?.filter(
                      (companyDetails) =>
                        companyDetails?.finishedApplication === true &&
                        companyDetails?.isApproved === true &&
                        companyDetails?.renewalStatus != RENEWAL_STATUS.MANUAL
                    )
                    ?.map((company) => {
                      return (
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                          onClick={() => switchCompany(company)}
                        >
                          <div className="rounded-full">
                            <Avatar
                              avatarStyling="w-10 h-10"
                              avatarImage={company?.logo}
                              avatarName={company?.companyName && company?.companyName?.charAt(0)}
                            />
                          </div>
                          <div className="flex flex-col w-full gap-1">
                            <div className="flex flex-row items-start justify-between w-full gap-2">
                              <MSText
                                title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                fontWeight="text-bold"
                              />
                              {company?._id == sessionStorage.getItem('companyId') &&
                                (sessionStorage.getItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED) ||
                                  companyList.length === 1) && <img src={Tick} width="16" height="16" />}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {companyList
                    ?.filter(
                      (companyDetails) =>
                        companyDetails?.finishedApplication === true && companyDetails?.isApproved !== true
                    )
                    ?.map((company) => {
                      return (
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                          onClick={() => switchCompany(company)}
                        >
                          <div className="rounded-full">
                            <Avatar
                              avatarStyling="w-10 h-10"
                              avatarImage={company?.logo}
                              avatarName={company?.companyName && company?.companyName?.charAt(0)}
                            />
                          </div>
                          <div className="flex flex-col w-full gap-1">
                            <div className="flex flex-row items-start justify-between w-full gap-2">
                              <MSText
                                title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                fontWeight="text-bold"
                              />
                            </div>
                            {company?.finishedApplication === true && company?.isApproved === false && (
                              <Badge color="green-200" title="Under Review" />
                            )}
                            {company?.finishedApplication === false && (
                              <Badge color="salmon-500" title="Ongoing Application" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {companyList
                    ?.filter((companyDetails) => companyDetails?.finishedApplication === false)
                    ?.map((company) => {
                      return (
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                          onClick={() => switchCompany(company)}
                        >
                          <div className="rounded-full">
                            <Avatar
                              avatarStyling="w-10 h-10"
                              avatarImage={company?.logo}
                              avatarName={company?.companyName && company?.companyName?.charAt(0)}
                            />
                          </div>
                          <div className="flex flex-col w-full gap-1">
                            <div className="flex flex-row items-start justify-between w-full gap-2">
                              <MSText
                                title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                fontWeight="text-bold"
                              />
                            </div>
                            {company?.finishedApplication === false && (
                              <Badge color="salmon-500" title="Ongoing Application" />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {companyList
                    ?.filter(
                      (companyDetails) =>
                        companyDetails?.renewalStatus === RENEWAL_STATUS?.MANUAL && companyDetails?.isApproved === true
                    )
                    ?.map((company) => {
                      return (
                        <>
                          <div
                            className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                            onClick={() => {
                              if (!JSON?.parse(sessionStorage?.getItem('closeRenewalModalClicked'))) {
                                handleCompanyExpiring(company);
                              } else {
                                switchCompany(company);
                              }
                            }}
                          >
                            <div className="rounded-full">
                              <Avatar
                                avatarStyling="w-10 h-10"
                                avatarImage={company?.logo}
                                avatarName={company?.companyName && company?.companyName?.charAt(0)}
                              />
                            </div>
                            <div className="flex flex-col w-full gap-1">
                              <div className="flex flex-row items-start justify-between w-full gap-2">
                                <MSText
                                  title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                  fontWeight="text-bold"
                                />
                              </div>
                              <Badge color="yellow-500" title="License Expiring Soon" />
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            )}

            {/* Register another company */}
            <div
              className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${!onHover && 'hover:bg-gray-100'} `}
              onClick={() => handleNewCompanyCreation()}
            >
              <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
              <MSText title="Register another company" textColor="text-blue-300" className="dropDown-email-text ml-2" />
            </div>

            {/* Switch to  statrys */}
            <div
              className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${!onHover && 'hover:bg-gray-100'} `}
              onClick={() => handleSwitchToStatrys()}
            >
              <div>{getSvgIcon(SVG_TYPES.SWITCH_TO_STATRYS)}</div>
              <MSText title="Switch to Statrys" textColor="text-blue-300" className="dropDown-email-text ml-2" />
            </div>

            {/* Business Account */}
            <div>
              <div
                className={`flex flex-row items-center rounded-lg px-4 py-4 mt-3 ${!onHover && 'hover:bg-gray-100'} `}
                onClick={() => {
                  setOpenBussinessAccountList((prev) => !prev);
                  setOpenInvoicingList(false);
                }}
              >
                <div>{getSvgIcon(SVG_TYPES.BUSINESS_ACCOUNT)}</div>
                <div className="flex justify-between items-center flex-grow">
                  <MSText title="Business Account" textColor="text-blue-300" className="dropDown-email-text ml-2" />
                  {openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                  {!openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
                </div>
              </div>
              {openBussinessAccountList && (
                <>
                  <div className="px-4 flex flex-col gap-3 mt-2">
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                  </div>
                  <div
                    className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 mt-2 ${
                      !onHover && 'hover:bg-gray-100'
                    } `}
                    onClick={() => handleNewCompanyCreation()}
                  >
                    <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                    <MSText
                      title="Apply for a Business Account"
                      textColor="text-blue-300"
                      className="dropDown-email-text ml-2"
                    />
                  </div>
                </>
              )}
            </div>

            {/* Invoicing */}
            <div>
              <div
                className={`flex flex-row items-center rounded-lg px-4 py-4  ${!onHover && 'hover:bg-gray-100'} `}
                onClick={() => {
                  setOpenBussinessAccountList(false);
                  setOpenInvoicingList((prev) => !prev);
                }}
              >
                <div>{getSvgIcon(SVG_TYPES.INVOICING)}</div>
                <div className="flex justify-between items-center flex-grow">
                  <MSText title="Invoicing" textColor="text-blue-300" className="dropDown-email-text ml-2" />
                  {openInvoicingList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                  {!openInvoicingList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
                </div>
              </div>
              {openInvoicingList && (
                <>
                  <div className="px-4 flex flex-col gap-3 mt-2">
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                    <SkeletonRectangle />
                  </div>
                  <div
                    className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 mt-2 ${
                      !onHover && 'hover:bg-gray-100'
                    } `}
                    onClick={() => handleNewCompanyCreation()}
                  >
                    <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                    <MSText
                      title="Manage your invoices"
                      textColor="text-blue-300"
                      className="dropDown-email-text ml-2"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toSwitchCompany: (companyPayload) => dispatch(CompanyAction.switchCompany(companyPayload))
  };
};
export default connect(null, mapDispatchToProps)(NavigationDropDownLeft);

NavigationDropDownLeft.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPositions: PropTypes.array,
  children: PropTypes.any
};
