import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack } from '@mui/material';
import CompanyStatus from '../../components/ui/CompanyStatus';
import { convertDateToDDMMYYYY } from '../../helper/helpers';
import { STATUS_COLORS } from '../../constants/enum';
import useIsMobile from '../../../utils/isMobile';

LatestUpdateTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onApproveRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  handleToShowLatestData: PropTypes.func
};

export default function LatestUpdateTableRow({ row, selected, handleToShowLatestData }) {
  const theme = useTheme();

  const { documentName, Identity, uploadedDate } = row;

  const isMobile = useIsMobile();
  return (
    <>
      {isMobile && (
        <TableRow
          hover
          selected={selected}
          onClick={handleToShowLatestData}
          sx={{
            borderBottom: `1px solid ${STATUS_COLORS.GREY}`,
            '&:last-child td, &:last-child th': { borderBottom: 0 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px 0px'
          }}
        >
          <div className="flex flex-col gap-2">
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                paddingLeft: isMobile ? '16px !important' : '24px'
              }}
              className="cursor-pointer p-0 pl-6 sm:p-6"
            >
              {documentName}
            </TableCell>
            <TableCell
              className="cursor-pointer p-0 pl-6 sm:p-6"
              align="left"
              sx={{ paddingLeft: isMobile ? '16px !important' : '24px' }}
            >
              {Identity?.map((status) => (
                <span className="sm:ml-2">
                  <CompanyStatus requiredCompanyStatus={status} />
                </span>
              ))}
            </TableCell>
          </div>
          <TableCell
            className="cursor-pointer p-0 pl-6 sm:p-6"
            align="right"
            sx={{ fontSize: '15px', paddingRight: isMobile ? '16px !important' : '24px' }}
          >
            {convertDateToDDMMYYYY(uploadedDate)}
          </TableCell>
        </TableRow>
      )}
      {!isMobile && (
        <TableRow
          hover
          selected={selected}
          onClick={handleToShowLatestData}
          sx={{
            borderBottom: `1px solid ${STATUS_COLORS.GREY}`,
            '&:last-child td, &:last-child th': { borderBottom: 0 }
          }}
        >
          <TableCell
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '16px'
            }}
            className="cursor-pointer"
          >
            {documentName}
          </TableCell>

          <TableCell className="cursor-pointer" align="right">
            {Identity?.map((status) => (
              <span className="ml-2">
                <CompanyStatus requiredCompanyStatus={status} />
              </span>
            ))}
          </TableCell>

          <TableCell className="cursor-pointer" align="right" sx={{ fontSize: '15px' }}>
            {convertDateToDDMMYYYY(uploadedDate)}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
