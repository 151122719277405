import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { FormControl } from '@material-ui/core';
import TextInput from '../ui/inputs/TextInput';
import BackButton from '../ui/buttons/BackButton';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { connect } from 'react-redux';
import AlertTriangle from '../../images/icons/alert-triangle.svg';
import { useForm, Controller } from 'react-hook-form';
import { API_CODE, MUI_COLORS, REGEX, SESSION_STORAGE_NAME } from '../../constants/enum';
import * as ActionsForRegistrationNewCompany from './redux/action';
import { handleEnter, sendHubspotRequest } from '../../helper/helpers';
import StepsIndicator from '../ui/StepsIndicator';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ValidationMessage from '../ui/ValidationMessage';
import flags from 'country-flag-icons/react/3x2';
import { NEW_COMPANY_INCORPORATION } from '../../service/services';
import { getUser } from '../authService/auth';

function PersonalInformation({
  prevStep,
  nextStep,
  setCompletedSteps,
  completedSteps,
  newCompanyData,
  newCompanyAllInfo,
  companyCreate,
  companyId,
  companyUpdate
}) {
  const emailRegex = REGEX.EMAIL_REGEX;
  const user = getUser();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    setError,
    reset,
    formState: { errors }
  } = useForm();

  const validatePhoneNumber = (phoneNumber) => {
    if (isValidPhoneNumber(phoneNumber)) {
      return true;
    } else {
      setError('phoneNumber', { shouldSelect: true });
      return false;
    }
  };

  useEffect(() => {
    reset({
      phoneNumber: newCompanyAllInfo?.phoneNumber
    });
  }, []);

  const sendHubSpot = async (data) => {
    let hubspotPayload = {
      fields: [
        {
          name: 'company_name_01',
          value: newCompanyAllInfo?.companyName ? newCompanyAllInfo?.companyName : ''
        },
        {
          name: 'industry',
          value: newCompanyAllInfo?.industry
        },
        {
          name: 'email',
          value: data?.email
        },
        {
          name: 'phone',
          value: data?.phoneNumber
        },
        {
          name: 'firstname',
          value: data?.name
        },
        {
          name: 'lastname',
          value: data?.lastName
        }
      ],
      context: {
        pageUri: `${process.env.SITEURL}/hong-kong-company-registration`,
        pageName: 'Company creation'
      }
    };
    var final_data = JSON.stringify(hubspotPayload);
    sendHubspotRequest(final_data, NEW_COMPANY_INCORPORATION.HUBSPOT_URL, true);
  };

  function showPhoneNumberInputFieldError(color) {
    const phoneNumberInputField = document.getElementsByClassName('PhoneInputInput');
    if (phoneNumberInputField) {
      phoneNumberInputField[0].style.backgroundColor = color;
    }
  }

  if (Object.prototype.hasOwnProperty.call(errors, 'phoneNumber')) {
    showPhoneNumberInputFieldError(MUI_COLORS.LIGHT_CREAM);
  }

  async function moveToNextStep(data) {
    setCompletedSteps({
      ...completedSteps,
      personalInformationCompleted: true
    });
    await sendHubSpot(data);
    nextStep();
  }
  const onSubmit = async (data) => {
    if (validatePhoneNumber(data?.phoneNumber)) {
      let newCompanyPayload = {
        ...newCompanyAllInfo,
        firstName: data.name,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data?.phoneNumber
      };
      await newCompanyData(newCompanyPayload);
      sessionStorage.setItem(
        SESSION_STORAGE_NAME.USERNAME,
        JSON.stringify({
          firstName: data.name,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data?.phoneNumber
        })
      );
      if (companyId) {
        const updateResponse = await companyUpdate({
          companyId: companyId,
          companyName: newCompanyAllInfo?.companyName,
          numberOfOwners: newCompanyAllInfo?.selectedNumberOfOwners,
          industries: [newCompanyAllInfo?.industry],
          industryDescription: newCompanyAllInfo?.industryDescription,
          accountingAssistance: newCompanyAllInfo?.accounting,
          firstName: data.name,
          lastName: data.lastName,
          email: data.email.trim(),
          phoneNumber: data?.phoneNumber,
          stakeholder: [
            {
              firstName: data.name,
              lastName: data.lastName,
              email: data.email.trim(),
              phoneNumber: data?.phoneNumber
            }
          ]
        });
        if (updateResponse?.status === API_CODE?.STATUS_200) {
          moveToNextStep(data);
        }
      } else {
        const createResponse = await companyCreate({
          companyName: newCompanyAllInfo?.companyName,
          numberOfOwners: newCompanyAllInfo?.selectedNumberOfOwners,
          industries: [newCompanyAllInfo?.industry],
          industryDescription: newCompanyAllInfo?.industryDescription,
          accountingAssistance: newCompanyAllInfo?.accounting,
          registrationCountry: newCompanyAllInfo?.registrationCountry,
          firstName: data.name,
          lastName: data.lastName,
          email: data.email.trim(),
          phoneNumber: data?.phoneNumber,
          stakeholder: [
            {
              firstName: data.name,
              lastName: data.lastName,
              email: data.email.trim(),
              phoneNumber: data?.phoneNumber
            }
          ]
        });
        if (createResponse?.status === API_CODE?.STATUS_200) {
          moveToNextStep(data);
        }
      }
    }
  };

  let back = () => {
    prevStep();
  };

  const PhoneInputErrorStyle = {
    backgroundColor: MUI_COLORS.LIGHT_CREAM,
    borderColor: MUI_COLORS.SUNSET_ORANGE,
    color: MUI_COLORS.SUNSET_ORANGE
  };

  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between company-registration-form-container">
      <StepsIndicator numberOfSteps={5} currentStepNumber={3} />
      <div>
        <MDSubtitleText className="md:mt-10 mt-4" fontWeight="text-bold" title="Share your personal information" />
        <form id="contact-form" className="" onSubmit={handleSubmit(onSubmit)}>
          <FormControl className="w-full">
            <div className="flex flex-col md:flex-row gap-4 md:mt-8 mt-4">
              <TextInput
                onKeyDown={handleEnter}
                name="name"
                label="First Name"
                placeholder="e.g. Paul"
                defaultValue={user?.firstName ? user?.firstName : newCompanyAllInfo?.firstName}
                className="w-full"
                inputRef={register({
                  required: true
                })}
                error={errors.name ? true : false}
                helperText={
                  errors.name ? (
                    <div className="flex gap-2 items-center">
                      <img src={AlertTriangle} alt="alert triangle" />
                      Please fill in a valid First Name
                    </div>
                  ) : null
                }
              />
              <TextInput
                onKeyDown={handleEnter}
                defaultValue={user?.lastName ? user?.lastName : newCompanyAllInfo?.lastName}
                name="lastName"
                label="Last Name"
                placeholder="e.g. Doe"
                className="w-full"
                inputRef={register({
                  required: true
                })}
                error={errors.lastName ? true : false}
                helperText={
                  errors.lastName ? (
                    <div className="flex gap-2 items-center">
                      <img src={AlertTriangle} alt="alert triangle" />
                      Please fill in a valid Last Name
                    </div>
                  ) : null
                }
              />
            </div>
            <TextInput
              onKeyDown={handleEnter}
              defaultValue={user?.email ? user?.email : newCompanyAllInfo?.email}
              margin="mt-4"
              name="email"
              label="Email Address"
              placeholder="name@domain.com"
              variant="filled"
              inputRef={register({
                required: true,
                pattern: {
                  value: emailRegex,
                  message: 'Please fill in a valid Email Address'
                }
              })}
              error={errors.email ? true : false}
              helperText={
                errors.email ? (
                  <div className="flex gap-2 items-center">
                    <img src={AlertTriangle} alt="alert triangle" />
                    Please fill in a valid Email Address
                  </div>
                ) : null
              }
            />
            <div className="mt-4">
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <PhoneInput
                    international
                    flags={flags}
                    placeholder="Phone Number"
                    style={errors.phoneNumber && PhoneInputErrorStyle}
                    value={newCompanyAllInfo?.phoneNumber ? newCompanyAllInfo?.phoneNumber : field?.value ?? ''}
                    onChange={(value) => {
                      setValue('phoneNumber', value);
                      field?.onChange(value);
                      clearErrors('phoneNumber');
                      showPhoneNumberInputFieldError(MUI_COLORS.WHITE);
                    }}
                  />
                )}
              />
              {errors.phoneNumber && <ValidationMessage title="Please fill in a valid Phone number." />}
            </div>
          </FormControl>
        </form>
      </div>

      <div className="flex md:flex-row flex-col-reverse justify-between md:mt-8 mt-4 items-center w-full">
        <BackButton onClick={back} className="w-full" />
        <PrimaryButton
          className="capitalize md:w-32 w-full onboarding-button"
          fontSize="text-base"
          caption="Continue"
          onClick={handleSubmit(onSubmit)}
          linkClass="md:w-auto w-full"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData,
    companyId: state?.newAccountSetup?.companyCreateDetails?.data?._id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(ActionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData)),
    companyCreate: (companyCreateData) => dispatch(ActionsForRegistrationNewCompany.companyCreate(companyCreateData)),
    companyUpdate: (companyUpdateData) => dispatch(ActionsForRegistrationNewCompany.companyUpdate(companyUpdateData))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);

PersonalInformation.propTypes = {
  prevStep: PropTypes.func,
  nextStep: PropTypes.number,
  setCompletedSteps: PropTypes.object,
  completedSteps: PropTypes.object,
  newCompanyData: PropTypes.object,
  newCompanyAllInfo: PropTypes.object,
  companyCreate: PropTypes.func,
  companyId: PropTypes.object,
  companyUpdate: PropTypes.func
};
