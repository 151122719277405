import React, { useEffect } from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

function CustomizedDialogs({
  children,
  openDynamicModal,
  closeDynamicModal,
  maxWidth,
  minWidth,
  hideCrossIcon,
  className
}) {
  const [open, setOpen] = React.useState(openDynamicModal);
  const handleClose = () => {
    setOpen(false);
    closeDynamicModal();
  };
  useEffect(() => {
    setOpen(openDynamicModal);
  }, [openDynamicModal]);
  const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes?.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            sx={{
              position: 'absolute',
              right: 16,
              top: hideCrossIcon ? -16 : 16,
              zIndex: 999
            }}
            aria-label="close"
            className={classes?.closeButton}
            onClick={onClose}
          >
            {!hideCrossIcon && <CloseIcon />}
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return (
    <MuiDialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={minWidth}
      maxWidth={maxWidth}
      className={className}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
    </MuiDialog>
  );
}

export default CustomizedDialogs;
