import React, { useState } from 'react';
import CompanyProfile from '../../components/companyIncorporation/profileSettings/CompanyProfile';
import Security from '../../components/companyIncorporation/profileSettings/Security';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Layout from '../../components/layout';
import Page from '../../components/Page';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import MSText from '../../components/ui/typography/MSText';
import { PAGE_TITLE } from '../../constants/enum';
import useIsMobile from '../../../utils/isMobile';

function SettingProfile() {
  const [buttonLoader, setButtonLoader] = useState(false);
  const isMobile = useIsMobile();
  return (
    <Layout noSideBar={isMobile ? true : false}>
      <Page title="Company" className="w-screen sm:w-full">
        <div className="px-2 sm:px-0">
          <HeaderBreadcrumbs
            heading={PAGE_TITLE.PROFILE}
            links={[{ name: '' }]}
            action={
              <>
                <button positive form="password-form" type="submit">
                  <PrimaryButton
                    isLoading={buttonLoader}
                    id="getpaid-create-invoice-btn"
                    caption={
                      <div className="flex gap-1 items-center">
                        <MSText textColor="text-white" fontWeight="text-bold" title="Save changes" />
                      </div>
                    }
                    type="small"
                  />
                </button>
              </>
            }
          />

          <div className="flex flex-col gap-4 sm:gap-0  sm:flex-row">
            <CompanyProfile />
            <Security setButtonLoader={setButtonLoader} />
          </div>
        </div>
      </Page>
    </Layout>
  );
}

export default SettingProfile;
