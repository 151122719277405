import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack, Divider } from '@mui/material';
import CompanyStatus from '../../components/ui/CompanyStatus';
import { MUI_COLORS, STATUS_COLORS, STRING_MAX_CHAR } from '../../constants/enum';
import MSText from '../../components/ui/typography/MSText';
import Iconify from '../../components/Iconify';
import { backgroundColor } from 'tailwindcss/defaultTheme';
import { convertDateToDDMMYYYY, stringTruncate, downloadImage } from '../../helper/helpers';
import * as commonReduxActions from '../../components/commonReduxStore/reduxStore/action';
import { connect } from 'react-redux';
import useIsMobile from '../../../utils/isMobile';

LatestUpdateTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  handleDeleteAction: PropTypes.func,
  handleSelectedRow: PropTypes.func
};

function LatestUpdateTableRow({
  row,
  selected,
  handleDeleteAction,
  selectedRow,
  handleSelectedRow,
  selectedRowData,
  isViewableTable,
  previewDocument
}) {
  const { documentName, uploadedDate, _id } = row;
  const handleDownloadDocument = async () => {
    if (row?.documentCode || row?.documentUrl) {
      const previewDocumentResponse = await previewDocument({
        [`${row?.documentCode ? 'code' : 'documentUrl'}`]: row?.documentCode ? row?.documentCode : row?.documentUrl,
        newVersion: row?.documentCode ? true : false
      });
      downloadImage(previewDocumentResponse?.data, row?.documentName);
    }
  };
  const isMobile = useIsMobile();
  return (
    <>
      <TableRow
        selected={selected}
        hover={selectedRowData !== _id && true}
        sx={
          isViewableTable
            ? selectedRowData === _id && {
                backgroundColor: MUI_COLORS.PURPLE
              }
            : selectedRow === _id && {
                backgroundColor: MUI_COLORS.PURPLE
              }
        }
        onClick={handleSelectedRow}
      >
        <TableCell sx={{ padding: isMobile && '16px !important' }}>
          <div className="flex items-center justify-between">
            <div>
              <Typography
                sx={
                  isViewableTable
                    ? selectedRowData === _id && { color: 'white' }
                    : selectedRow === _id
                    ? { color: 'white' }
                    : {
                        color: MUI_COLORS.MID_NIGHT,
                        textDecoration: 'none',
                        boxShadow: 'none',
                        fontSize: '16px',
                        fontWeight: 400
                      }
                }
                variant="subtitle2"
                noWrap
              >
                {stringTruncate(documentName, STRING_MAX_CHAR.TWENTY_NINE)}
              </Typography>
              <MSText
                textColor={
                  isViewableTable
                    ? selectedRowData === _id && 'text-white'
                    : selectedRow === _id
                    ? 'text-white'
                    : 'text-gray-450'
                }
                title={convertDateToDDMMYYYY(uploadedDate)}
              />
            </div>
            <div className="flex justify-end">
              {(isViewableTable ? selectedRowData !== _id : selectedRow !== _id) && (
                <>
                  <div className="bg-customBg-mainBg text-coral-500 rounded-lg p-2 cursor-pointer">
                    <Iconify icon={'lucide:download-cloud'} onClick={handleDownloadDocument} />
                  </div>
                </>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
      <Divider sx={{ color: STATUS_COLORS.GREY, width: '344px' }} />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    previewDocument: (uploadedImage) => dispatch(commonReduxActions.previewDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(LatestUpdateTableRow);
